/* eslint-disable vue/valid-v-for */
<template>
  <div>
    <!-- 订单详情 -->
    <div class="header_title">
      <span>{{ $t("order.orderDetail") }}</span>
      <span class="to_back" @click="toBack">{{
        $t("order.toBackOrderLists")
      }}</span>
    </div>
    <!-- 用户信息 -->
    <div class="header_title mt10">
      <span>{{ $t("order.orderUserInformation") }}</span>
    </div>
    <ul class="info_lists">
      <li>
        <div class="title">{{ $t("order.userName") }} :</div>
        <div class="value">{{ data.userName }}</div>
      </li>
      <li>
        <div class="title">{{ $t("order.contactInformation") }} :</div>
        <div class="value">{{ data.orderNumber }}</div>
      </li>
      <li class="last">
        <div class="title">{{ $t("order.userAvatar") }} :</div>
        <div class="value">
          <img
            style="width: 30px; height: 30px; margin-top: 5px"
            :src="data.userImg"
          />
        </div>
      </li>
      <li class="last">
        <div class="title">{{ $t("order.userID") }} :</div>
        <div class="value">{{ data.userId }}</div>
      </li>
    </ul>
    <!-- 收货及物流信息 -->
    <div class="header_title mt10">
      <span>{{ $t("order.receivingInformation") }}</span>
    </div>
    <ul class="info_lists" v-if="data.shippingDeatils">
      <li>
        <div class="title">{{ $t("order.consignee") }} :</div>
        <div class="value">{{ data.shippingDeatils.titlename }}</div>
      </li>
      <li>
        <div class="title">{{ $t("order.email") }} :</div>
        <div class="value">{{ data.shippingDeatils.email }}</div>
      </li>
      <li>
        <div class="title">{{ $t("order.receivingContactInformation") }} :</div>
        <div class="value">{{ data.shippingDeatils.mobile }}</div>
      </li>
      <li>
        <div class="title">{{ $t("order.receivingAddress") }} :</div>
        <div class="value">{{ data.shippingDeatils.addr4 }}</div>
      </li>

      <li>
        <div class="title">
          {{ $t("order.freightForwardingCompanyPhone") }} :
        </div>
        <div class="value">{{ data.transportDeatils.contackNumber }}</div>
      </li>
      <li>
        <div class="title">{{ $t("order.forwardingCompanyAddress") }} :</div>
        <div class="value">{{ data.transportDeatils.contackAddress }}</div>
      </li>
      <li>
        <div class="title">{{ $t("order.invoiceOrNot") }} :</div>
        <div class="value"></div>
      </li>
      <li>
        <div class="title">{{ $t("order.leavingAMessage") }} :</div>
        <div class="value">{{ data.transportDeatils.remarks }}</div>
      </li>

      <li>
        <div class="title">{{ $t("order.nameOfLogistics") }} :</div>
        <div class="value">{{ data.transportDeatils.logisticsName }}</div>
      </li>
      <li>
        <div class="title">{{ $t("order.waybillNo") }} :</div>
        <div class="value">{{ data.orderNumber }}</div>
      </li>
    </ul>
    <!-- 物流信息 -->
    <!-- 待收货 -->
    <div class="logistics_information" v-if="physical_images.length > 0">
      <div class="title mt1">
        <span>{{ $t("order.logisticsOrder") }}:</span>
      </div>


      <a :href="img" target="_blank" v-for="(img, index) in physical_images" :key="index"><img :src="img" class="logoUrl" /></a>
      <!-- <div class="content">
        {{ $t("order.noInformationYet") }}
      </div> -->
    </div>

    <div class="logistics_information" v-if="data.lading && data.lading.length > 0">
      <div class="title mt1">
        <span>{{ $t("order.ladingImage") }}:</span>
      </div>

      <a
        :href="item.img"
        target="_blank"
        v-for="(item, index) in data.lading"
        :key="index"
      >
        <img class="logoUrl" :src="item.img" />
      </a>
    </div>

    <!-- 待验收 -->
    <!-- <div class="logistics_information" >
      <div class="title mt1">
        <span>{{ $t("order.logisticsOrder") }}:</span>
      </div>
      <img :src="this.logoUrl" class="logoUrl" />
    </div> -->

    <!-- 待付款 -->
    <!-- <div
      class="logistics_information"
      v-for="(item, index) in physicals"
      :key="index"
    >
      <div class="title mt1">
        <span>{{ $t("order.logisticsOrder") }}:</span>
      </div>
      <img :src="item.physical.img" class="logoUrl" />
      <img :src="this.logoUrl" class="logoUrl" />
    </div> -->
    <!-- 其他状态 -->
    <!-- <div class="logistics_information">
      <div class="title mt1">
        <span>{{ $t("order.logisticsInformation") }}</span>
      </div>
    </div> -->

    <!-- 订单信息 -->
    <div class="header_title mt10">
      <span>{{ $t("order.orderInformation") }}</span>
    </div>
    <!-- 订单编号 -->

    <!-- 待付款 paymentPending-->
    <div>
      <div class="mt10"></div>
      <el-card class="mt">
        <div class="order_status">
          {{ $t("order.orderNo") }}:{{ data.orderNumber }}
          <div class="order_time">
            {{ $t("order.transactionTime") }}: {{ data.createdAt }}
          </div>
        </div>

        <!--  待付款 paymentPending -->
        <el-card class="mt" v-if="status == 'paymentPending'">
          <div class="fl">
            <p>{{ $t("order.pendingPayment") }}</p>
          </div>
          <div class="clear"></div>
        </el-card>

        <!-- 待发货 readyToShip-->
        <el-card class="mt" v-if="status == 'readyToShip'">
          <div class="fl">
            <p class="mt1">{{ $t("order.toBeShipped") }}</p>
          </div>
          <div class="fr">
            <el-button class="btn" @click="ship(ids)">{{
              $t("order.ship")
            }}</el-button>
          </div>
          <div class="clear"></div>
        </el-card>

        <!-- 待收货 shipped-->
        <el-card class="mt" v-if="status == 'shipped'">
          <div class="fl">
            <p class="mt1">{{ $t("order.toBeReceived") }}</p>
          </div>
          <div class="fr">
            <el-button class="btn" style="width: auto" @click="billOfLading">{{
              $t("order.billOfLading")
            }}</el-button>
          </div>
          <div class="clear"></div>
        </el-card>

        <!-- 待验收 inspect-->
        <el-card class="mt toBeAccepted" v-if="status == 'inspect'">
          <div class="fl">
            <p>{{ $t("order.toBeAccepted") }}</p>
          </div>
          <div class="fr">
            <p>{{ $t("order.if") }}</p>
          </div>
          <div class="clear"></div>
        </el-card>

        <!-- 已收货 delivered-->
        <el-card class="mt" v-if="status == 'delivered'">
          <div class="fl">
            <p class="mt1">{{ $t("order.received") }}</p>
          </div>
          <div class="clear"></div>
        </el-card>

        <!-- 退换货 cancelled-->
        <el-card class="mt" v-if="status == 'cancelled'">
          <div class="fl">
            <p class="mt1">{{ $t("order.Return") }}</p>
          </div>
          <div class="fr">
            <el-button
              class="btn"
              style="background: #c4c4c4"
              @click="refuse"
              >{{ $t("order.refuse") }}</el-button
            >
            <el-button class="btn" @click="agree">{{
              $t("order.agree")
            }}</el-button>
          </div>
          <div class="clear"></div>
        </el-card>
        <!-- 总金额 -->
        <el-card>
          <div class="fl order_pay_data">
            <div class="list">
              {{ $t("order.subTotal") }}：${{ data.subTotal }}
            </div>
            <div class="list">
              {{ $t("order.deposit") }}：${{ data.deposit }}

              <button
                class="edit_amount"
                @click="depositVisible = true"
                v-if="status == 'paymentPending'"
              >
                {{ $t("order.editDeposit") }}
              </button>
            </div>
            <div class="list">
              {{ $t("order.balance") }}: ${{ data.balance }}
            </div>
          </div>

          <div class="clear"></div>
        </el-card>
        <!-- 备注 -->
        <el-card> {{ $t("order.remarks") }}: {{ data.remarks }} </el-card>
        <!-- 标题 -->
        <el-card class="titles mt">
          <el-row>
            <el-col :span="4">
              <p>{{ $t("order.itemID") }}</p>
            </el-col>
            <el-col :span="3" align="center">
              <p>{{ $t("order.productPicture") }}</p>
            </el-col>
            <el-col :span="6" align="center">
              <p>{{ $t("order.title") }}</p>
            </el-col>
            <el-col :span="6" align="center">
              <p>{{ $t("order.specifications") }}</p>
            </el-col>

            <el-col :span="2" align="center">
              <p>{{ $t("order.quantity") }}</p>
            </el-col>
            <el-col :span="3" align="center">
              <p>{{ $t("order.amountOfMoney") }}</p>
            </el-col>
          </el-row>
        </el-card>
        <!-- 内容 -->
        <el-card
          class="main"
          v-for="(item, index) in data.productData"
          :key="index"
        >
          <el-row>
            <el-col :span="4">
              <p>{{ item._id }}</p>
            </el-col>
            <el-col :span="3" align="center">
              <img :src="item.img" />
            </el-col>
            <el-col :span="6" align="center">
              <p>{{ item.productName }}</p>
            </el-col>
            <el-col :span="6" align="center">
              <p v-for="(size, s) in item.sizeData" :key="s">
                {{ size.color }}: {{ size.size }}
              </p>
            </el-col>

            <el-col :span="2" align="center">
              <p v-for="(size, s) in item.sizeData" :key="s">
                {{ size.quantity }}
              </p>
            </el-col>
            <el-col :span="3" align="center">
              <p v-for="(size, s) in item.sizeData" :key="s">
                ${{ size.price }}
              </p>
            </el-col>
          </el-row>
        </el-card>
        <div class="actualReceipts">
          <p>
            {{ $t("order.actualReceipts") }}:<span>${{ data.payMoney }}</span>
          </p>
        </div>
      </el-card>

      <!-- 协商历史 -->
      <el-card class="mt mb60" v-if="status == 'cancelled'">
        <h4>{{ $t("order.history") }}</h4>
        <!-- 循环 -->
        <div class="mt history">
          <img src="@/assets/Ellipse_81.png" class="fl mr" />
          <div>
            <p class="name">Flagship store</p>
            <p class="time fr">2021-05-21 10：00：25</p>
          </div>
          <p class="mt1">The merchant agrees to refund</p>
        </div>
      </el-card>
    </div>

    <!-- 上传提单弹框 -->
    <el-dialog
      :title="$t('order.billOfLading')"
      :visible="dialogVisibles"
      width="35%"
      @close="dialogVisibles = false"
    >
      <div class="billOfLading">
        <div class="list" v-for="(img, index) in data.lading" :key="index">
          <i
            class="el-dialog__close el-icon el-icon-close"
            @click="deleteLading(img._id)"
          ></i>
          <img :src="img.img" class="fl avatar" />
        </div>
      </div>
      <div class="dialog-footer">
        <el-button type="primary" @click="swipersAddDialog = true">{{
          $t("common.add")
        }}</el-button>

        <el-button @click="dialogVisibles = false">{{
          $t("common.cancel")
        }}</el-button>
      </div>
    </el-dialog>

    <el-dialog
      :title="$t('common.add')"
      :visible="swipersAddDialog"
      @close="swipersAddDialog = false"
      width="35%"
    >
      <el-form ref="form" label-width="120px">
        <el-form-item :label="$t('shop.img')">
          <input
            type="file"
            id="swiperImageUpload"
            accept="image/gif,image/jpeg,image/png,image/jpg"
            @change="swiperImageUpload"
          />
        </el-form-item>
      </el-form>

      <div class="dialog-footer">
        <el-button @click="swipersAddDialog = false">{{
          $t("common.close")
        }}</el-button>
        <el-button type="primary">{{ $t("common.done") }}</el-button>
      </div>
    </el-dialog>
    <!-- 订金 -->
    <el-dialog
      :title="$t('order.editDeposit')"
      :visible="depositVisible"
      @close="depositVisible = false"
      width="35%"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item :label="$t('order.deposit') + '($)'">
          <el-input
            v-model="order_deposit"
            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
            placeholder="0.00"
          ></el-input>
        </el-form-item>
      </el-form>

      <div class="dialog-footer">
        <el-button @click="depositVisible = false">{{
          $t("common.close")
        }}</el-button>
        <el-button type="primary" @click="doneDeposit">{{
          $t("common.done")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getData, createData, updateSetting } from "../../helper/api";
const moment = require("moment");
export default {
  data() {
    return {
      logoUrl: "",
      times: 250,
      physicals: [],
      id: 0,
      status: "",
      data: [],
      createdAt: "",
      logisticsorder: "",
      dialogImageUrl: "",
      dialogVisible: false,
      dialogVisibles: false,
      swipersAddDialog: false,
      physical_images: [],
      physicalData: {},
      physical_image: "",
      depositVisible: false,
      order_deposit: "",
      form: {
        logistics_name: "",
        waybill_no: "",
      },
      show: false,
      tableData: [
        {
          id: "",
          quantity: "",
          discount: "",
          orderStatus: "",
          address: "",
          amount: "",
          reason: "",
          picture: "",
          instructions: "",
          returnStatus: "",
        },
      ],
    };
  },
  created() {
    this.getOrder(this.$route.query.id, this.$route.query.status);
    // this.logoUrl = localStorage.getItem("logoUrl");
    // this.getTime();
  },
  methods: {
    deleteLading(id) {
      updateSetting("PUT", "order/delete-lading", {
        orderId: this.$route.query.id,
        ladingId: id,
      }).then();
      this.getOrder(this.$route.query.id, this.$route.query.status);
    },
    doneDeposit() {
      if (parseFloat(this.order_deposit) > 0) {

        if (this.order_deposit > this.data.subTotal) {
            this.$message({
                message: this.$t('order.depositAmountThan'),
                type: 'error'
            });
            return false;
        }

        updateSetting("PUT", "order/deposit", {
          orderId: this.$route.query.id,
          deposit: this.order_deposit,
        }).then((res) => {
          if (res.message == "update successfully.") {
            this.$message({
              message: this.$t("common.updateSuccess"),
              type: "success",
            });
          } else {
            this.$message({
              message: res.message,
              type: "error",
            });
          }
          this.getOrder(this.$route.query.id, this.$route.query.status);
        });
      } else {
        this.$message({
          message: this.$t("order.depositAmount"),
          type: "error",
        });
      }
    },
    swiperImageUpload(e) {
      let _this = this;
      _this.swiperObj = e.target.files["0"];
      let fr = new FileReader();
      fr.onload = function () {
        _this.swiperImage = fr.result;
        var image = _this.swiperImage.replace(/^data:image\/\w+;base64,/, "");

        _this.swipersAddDialog = false;

        createData("POST", "image/common", {
          dir: "physical_images",
          name:
            moment(new Date()).format("YYYYMMDDHHmmss") +
            Math.round(Math.random() * 99999),
          image: image,
        }).then((res) => {
          // _this.physical_image = res.data.image;
          // console.log(_this.logoUrl);

          createData("PUT", "order/update-lading", {
            orderId: _this.$route.query.id,
            img: res.data.image,
          }).then((res) => {
            console.log(res.message);
            if (res.message == "update successfully.") {
              _this.$message({
                message: _this.$t("common.updateSuccess"),
                type: "success",
              });
            } else {
              _this.$message({
                message: res.message,
                type: "success",
              });
            }
            _this.getOrder(_this.$route.query.id, _this.$route.query.status);
          });
        });
      };
      fr.readAsDataURL(_this.swiperObj);
    },
    // 获取数据
    getOrder(id, status) {
      getData("get", "order", id).then((res) => {
        console.log(res);
        this.status = status;
        this.ids = res.data._id;
        this.data = res.data;
        this.physicals = res.data.physicals;
        this.data.createdAt = moment(this.data.createdAt).format(
          "YYYY/MM/DD HH:mm"
        );
        this.order_deposit = this.data.deposit;


        res.data.physicals.forEach((item, index) => {
            if (index == 0) {
                this.physical_images = item.physical.img;
                this.physicalData = item.physical;
            }
        });

        //产品
        this.data.productData.forEach((item, index) => {
          var sizeData = [];
          item.cars.forEach((car) => {
            car.specs.forEach((spec) => {
              sizeData.push({
                color: car.name,
                size: spec.name,
                quantity: spec.num,
                price: spec.price,
              });
            });
          });
          this.data.productData[index]["sizeData"] = sizeData;
        });
      });
    },

    // 发货弹框
    deliveryGoods() {
      this.dialogVisible = true;
    },
    // 上传提单弹框
    billOfLading() {
      this.dialogVisibles = true;
    },
    // 关闭弹框
    closeDialog() {
      this.dialogVisible = false;
      this.dialogVisibles = false;
    },
    // 上传
    done() {
      this.dialogVisibles = false;
    },
    // 返回
    toBack() {
      this.$router.go(-1);
    },
    // 发货
    ship(id) {
      console.log(id);
      this.$router.push({
        path: "/orders/ship",
        query: {
          id,
        },
      });
    },
    // 拒绝
    refuse() {},
    // 同意
    agree() {
      this.$router.push("/orders/commodity");
    },
    // 倒计时
    // getTime() {
    //   this.timer = setInterval(() => {
    //     this.times--;
    //     if (this.times === 0) {
    //       clearInterval(this.timer);
    //     }
    //   }, 1000);
    // },
  },
};
</script>

<style scoped>
.mt10 {
  margin-top: 15px;
}
.mb60 {
  margin-bottom: 60px;
}
.header_title {
  background-color: #ffffff;
  border-radius: 5px;
  height: 43px;
  line-height: 43px;
  font-size: 14px;
}
.header_title span {
  margin-left: 15px;
  font-weight: bold;
}
.to_back {
  float: right;
  margin-right: 15px;
  color: red;
  cursor: pointer;
}
.info_lists {
  margin-top: 10px;
  background-color: #ffffff;
  height: auto;
  overflow: hidden;
  border-radius: 5px;
}
.info_lists li {
  float: left;
  width: 50%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px #eec6a2 solid;
  font-size: 13px;
}
.info_lists li.last {
  border-bottom: none;
}
.info_lists li .title {
  float: left;
  width: 29%;
  background-color: #ffead7;
  text-align: center;
  padding-right: 5px;
}
.info_lists li .value {
  float: left;
  width: 68%;
  background-color: #ffffff;
  text-align: center;
  padding-left: 10px;
}
.logistics_information {
  height: 150px;
  overflow: hidden;
  background-color: #ffffff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 13px;
}
.logistics_information .title {
  height: 30px;
  line-height: 30px;
  padding-left: 15px;
}
.logistics_information .title span {
  font-size: 14px;
  border-bottom: 2px #eec6a2 solid;
  font-weight: bold;
}
.logistics_information .content {
  padding: 15px;
}
/* .order_info {
  height: 40px;
  line-height: 40px;
  background-color: #ffffff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px #eec6a2 solid;
}
.order_info .order_no {
  float: left;
  margin-left: 15px;
  font-size: 13px;
}
.order_info .order_no span {
  color: red;
}*/
.order_status {
  margin-right: 15px;
  font-size: 15px;
  color: #000000;
  font-weight: bold;
}
.order_status .order_time {
  float: right;
}
/* .order_info .deliveryGoods {
  background-color: red;
  color: #ffffff;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  padding-right: 10px;
  padding-left: 10px;
  cursor: pointer;
} */
.order_product_lists {
  background-color: #ffffff;
  width: 100%;
  font-size: 13px;
}
.order_product_lists li {
  width: 100%;
  min-height: 40px;
  overflow: hidden;
  line-height: 20px;
  margin-left: 15px;
  margin-right: 15px;
}
.order_product_lists div {
  float: left;
}
.order_product_lists .id {
  width: 15%;
}
.order_product_lists .picture {
  width: 8%;
}
.order_product_lists .picture img {
  width: 50px;
  height: 50px;
}
.order_product_lists .title {
  width: 31%;
}
.order_product_lists .spec {
  width: 15%;
  text-align: center;
}
.order_product_lists .money {
  width: 8%;
  text-align: center;
}
.order_product_lists .quantity {
  width: 8%;
  text-align: center;
}
.order_product_lists .status {
  width: 15%;
  text-align: center;
}
.receipts {
  height: 50px;
  line-height: 50px;
  text-align: right;
  padding-right: 15px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #ffffff;
  border-top: 1px #eec6a2 solid;
  font-size: 13px;
}
.receipts span {
  color: red;
}
.bank {
  background-color: #ffffff;
  border-radius: 5px;
  min-height: 40px;
  overflow: hidden;
  line-height: 40px;
  font-size: 14px;
}
.bank span {
  display: block;
  width: 25%;
  float: left;
  margin-left: 15px;
  margin-right: 7%;
}
.el-dialog__body {
  height: auto;
  overflow: hidden;
}
.dialog-footer {
  text-align: right;
}
.received {
  margin-top: 20px;
  height: 50px;
  line-height: 50px;
  border: 1px solid #ebeef5;
  border-bottom: none;
  font-size: 15px;
}
.received span {
  margin-left: 30px;
}
.received .refuse {
  width: 128px;
  background: gray;
  color: #fff;
  margin-right: 10px;
}
.received .agree {
  width: 128px;
  background: #ff0a0a;
  color: #fff;
  margin-right: 20px;
}
.table {
  font-size: 13px;
}
.titles p {
  font-size: 15px;
  font-weight: 700;
}
.main {
  margin-top: 5px;
}
.main p {
  font-size: 15px;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.main img {
  margin-top: -7px;
  width: 72px;
  height: 52px;
}
/* .main:hover p {
  overflow: visible;
} */
.actualReceipts {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
.actualReceipts p {
  font-size: 15px;
  font-weight: 700;
  color: #000;
}
.actualReceipts span {
  margin-left: 5px;
  font-size: 15px;
  color: #ff0a0a;
}
.btn {
  width: 128px;
  font-size: 15px;
  background: #ff0a0a;
  color: #fff;
}
.bottom span {
  font-size: 13px;
  font-weight: 700;
}
.toBeAccepted p {
  font-size: 15px;
}
.billOfLading {
  min-height: 150px;
  overflow: hidden;
  margin-bottom: 30px;
}
.billOfLading .list {
  position: relative;
  height: auto;
  width: 110px;
  float: left;
}
.billOfLading .list .el-icon-close {
  position: absolute;
  margin-right: 50px;
  background-color: red;
  border-radius: 50%;
  z-index: 10000;
  left: 90px;
  width: 20px;
  color: #ffffff;
  text-align: center;
  padding-top: 1px;
  cursor: pointer;
}
.billOfLading img {
  width: 100px;
  height: 100px;
  margin: 5px;
}
/* .bottom p {
  margin-right: 110px;
  font-size: 13px;
  font-weight: 700;
} */
.history .name {
  font-size: 15px;
}
.history .time {
  font-size: 12px;
  color: gray;
}
.history p {
  font-size: 12px;
}
.logoUrl {
  margin-top: 10px;
  margin-left: 20px;
  width: 100px;
  height: 80px;
}
.countdown {
  position: relative;
}
.countdown img {
  position: absolute;
  top: 3px;
  width: 17px;
  height: 17px;
}
.countdown span {
  margin-left: 25px;
}
.order_pay_data {
  width: 100%;
}
.order_pay_data .list {
  float: left;
  width: 30%;
}
.order_pay_data .list .edit_amount {
  background-color: red;
  color: #ffffff;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.avatar {
  margin-left: 10px;
}
</style>